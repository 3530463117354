<template>
    <div>
        <el-tabs
            v-model="activeName"
            @tab-click="handleClick"
        >
            <el-tab-pane
                label="平台业绩"
                name="first"
            >
                <ul
                    class="e-task-items"
                    v-if="achievementData && achievementData.length>0"
                >
                    <li
                        class="e-task-item"
                        v-for="(item, index) in achievementData"
                        :key="index"
                        @click="detailShow(item)"
                    >
                        <div class="top">
                            <h4 class="title task_title">{{item.task_name}}</h4>
                            <div
                                class="right-txt"
                                style="width:273px;"
                            >
                                <span
                                    class="status"
                                    style="padding-left: 5px;"
                                    :class="{ green: item.status == 3, blue: item.status ==4, orange: item.status == 5, red: item.status ==6}"
                                > {{ item.status_value }}</span>
                                <span
                                    class="price"
                                    v-if="item.task_type==1 || item.task_type==2"
                                >
                                    ￥
                                    <strong>{{item.trade?item.trade.remuneration:item.remuneration}}</strong>
                                </span>
                                <span
                                    class="price"
                                    v-else
                                >
                                    ￥
                                    <strong>{{ item.trade?item.trade.remuneration:item.remuneration }}</strong>
                                </span>
                            </div>
                        </div>
                        <div class="bottom task_tag">
                            <div class="tag-box">
                                <span
                                    class="jiaji"
                                    v-if="item.is_hot==1"
                                >
                                    <i class="jk-icon-jiaji"></i>热门
                                </span>

                                <span :class="{jingjia: item.task_type == 1,bixuan:  item.task_type  == 2,bixuan:  item.task_type == 3}">{{ item.task_type_value }}</span>
                            </div>
                            <div
                                class="tips-box tips_box "
                                style="width:273px;"
                            >
                                <span style="padding-right:20px;margin-left: 0px;">{{ item.speciality_type_value }}</span>
                                <span>
                                    {{ item.enroll_count }} 人投标
                                </span>
                                <span>
                                    {{ item.trade.work_end_time }} 截止
                                </span>
                            </div>
                        </div>
                    </li>
                </ul>
                <p
                    v-else
                    style="width:100%;text-align:center;height:150px;line-height:150px;font-size:14px;color:#999;"
                >暂无数据</p>

                <el-dialog
                    title="平台业绩详情"
                    :visible.sync="terraceDialog"
                    width="800px"
                    center
                >
                    <ul class="detail-items">
                        <li>
                            <em>项目名称：</em>
                            <h4>{{detail.task_name}}</h4>
                        </li>
                        <li>
                            <em>项目编号：</em>
                            <p>{{detail.sn}}</p>
                        </li>
                        <li>
                            <em>完成时间：</em>
                            <p>{{detail.end_time_value}}</p>
                        </li>
                        <li>
                            <em>项目酬金：</em>
                            <span>
                                ￥
                                <strong>{{detail.remuneration}}</strong>元
                            </span>
                        </li>
                        <li>
                            <em>雇 主：</em>
                            <p>{{detail.employer_name}}</p>
                        </li>
                        <li v-if="detail.appraise && detail.appraise.employer_content">
                            <em>雇主评价：</em>
                            <p>{{detail.appraise.employer_content}}</p>
                        </li>
                        <li
                            v-if="detail.appraise && detail.appraise.employer_content"
                            class="row-star starpl-wrap"
                        >
                            <em>工作态度：</em>
                            <el-rate
                                style="float:left;"
                                class="appraise"
                                :value="detail.appraise.attitude_score"
                                disabled
                                show-score
                                text-color="#ff9900"
                                score-template
                            ></el-rate>
                            <span>{{detail.appraise.attitude_score}}分</span>
                        </li>
                        <li
                            v-if="detail.appraise && detail.appraise.employer_content"
                            class="row-star starpl-wrap"
                        >
                            <em>工作速度：</em>
                            <el-rate
                                style="float:left;"
                                class="appraise"
                                :value="detail.appraise.speed_score"
                                disabled
                                show-score
                                text-color="#ff9900"
                                score-template
                            ></el-rate>
                            <span>{{detail.appraise.speed_score}}分</span>
                        </li>
                        <li
                            v-if="detail.appraise && detail.appraise.employer_content"
                            class="row-star starpl-wrap"
                        >
                            <em>工作质量：</em>
                            <el-rate
                                style="float:left;"
                                class="appraise"
                                :value="detail.appraise.quality_score"
                                disabled
                                show-score
                                text-color="#ff9900"
                                score-template
                            ></el-rate>
                            <span>{{detail.appraise.quality_score}}分</span>
                        </li>
                    </ul>
                    <span
                        slot="footer"
                        class="dialog-footer"
                    >
                        <el-button @click="terraceDialog = false">返回</el-button>
                    </span>
                </el-dialog>
            </el-tab-pane>
            <el-tab-pane
                label="其他业绩"
                name="second"
            >
                <span style="padding-left: 20px;font-size: 14px;color: #66666680;">此部份为工程极客自己提交，仅供参考</span>
                <ul class="score-items">
                    <li
                        class="item"
                        v-for="(item,index) in value.attestation_info.achievement"
                        :key="index"
                        @click="otherDetailShow(item)"
                        style="height:40px;margin-bottom:10px;border-bottom:1px dashed #ddd;"
                    >
                        <el-row>
                            <el-col
                                :span="14"
                                class="title"
                            >{{item.title}}</el-col>
                            <el-col
                                :span="10"
                                class="date"
                            >时间：{{item.starttime}} - {{item.endtime}}</el-col>
                        </el-row>
                    </li>
                </ul>

                <el-dialog
                    title="其他业绩详情"
                    :visible.sync="otherDialog"
                    width="800px"
                    center
                >
                    <ul class="detail-items">
                        <li>
                            <em>项目名称：</em>
                            <h4 v-text="otherDetailData.title"></h4>
                        </li>
                        <li>
                            <em>项目时间：</em>
                            <p v-text="otherDetailData.starttime"></p>
                        </li>
                        <li>
                            <em>项目描述：</em>
                            <p v-text="otherDetailData.description"></p>
                        </li>
                        <li>
                            <em>项目职责：</em>
                            <p v-text="otherDetailData.responsibility"></p>
                        </li>
                        <li>
                            <em>委托单位：</em>
                            <p v-text="otherDetailData.company"></p>
                        </li>
                        <li>
                            <em>项目负责人：</em>
                            <p
                                v-for="(item,index) in otherDetailData.leader"
                                :key="index"
                            >{{item}}</p>
                        </li>
                    </ul>
                    <span
                        slot="footer"
                        class="dialog-footer"
                    >
                        <el-button @click="otherDialog = false">返回</el-button>
                    </span>
                </el-dialog>
            </el-tab-pane>
        </el-tabs>
        <!-- 
        <div class="firm-panel">
            <h3 class="g-title">平台业绩</h3>
            <ul class="e-task-items"
                    v-if="achievementData && achievementData.length>0">
                    <li class="e-task-item"
                        v-for="(item, index) in achievementData"
                        :key="index"
                        @click="detailShow(item)">
                        <div class="top">
                        <h4
                                class="title task_title">{{item.task_name}}</h4>
                            <div class="right-txt" style="width:273px;">
                                <span class="status" style="padding-left: 5px;"
                                      :class="{ green: item.status == 3, blue: item.status ==4, orange: item.status == 5, red: item.status ==6}"> {{ item.status_value }}</span>
                                <span class="price"
                                      v-if="item.task_type==1 || item.task_type==2">
                                    ￥
                                    <strong>{{item.trade?item.trade.remuneration:item.remuneration}}</strong>
                                </span>
                                <span class="price"
                                      v-else>
                                    ￥
                                    <strong>{{ item.trade?item.trade.remuneration:item.remuneration }}</strong>
                                </span>
                            </div>
                        </div>
                        <div class="bottom task_tag">
                            <div class="tag-box">
                                <span class="jiaji"
                                      v-if="item.is_hot==1">
                                    <i class="jk-icon-jiaji"></i>热门
                                </span>

                                <span
                                      :class="{jingjia: item.task_type == 1,bixuan:  item.task_type  == 2,bixuan:  item.task_type == 3}">{{ item.task_type_value }}</span>
                            </div>
                            <div class="tips-box tips_box " style="width:273px;">
                                <span style="padding-right:20px;margin-left: 0px;">{{ item.speciality_type_value }}</span>
                                <span>
                                    {{ item.enroll_count }} 人投标
                                </span>
                                <span>
                                    {{ item.trade.work_end_time }} 截止
                                </span>
                            </div>
                        </div>
                    </li>
                </ul>
                <p v-else style="width:100%;text-align:center;height:150px;line-height:150px;font-size:14px;color:#999;">暂无数据</p>

            <el-dialog title="平台业绩详情" :visible.sync="terraceDialog" width="800px" center>
                <ul class="detail-items">
                    <li>
                        <em>项目名称：</em>
                        <h4>{{detail.task_name}}</h4>
                    </li>
                    <li>
                        <em>项目编号：</em>
                        <p>{{detail.sn}}</p>
                    </li>
                    <li>
                        <em>完成时间：</em>
                        <p>{{detail.end_time_value}}</p>
                    </li>
                    <li>
                        <em>项目酬金：</em>
                        <span>
                            ￥
                            <strong>{{detail.remuneration}}</strong>元
                        </span>
                    </li>
                    <li>
                        <em>雇 主：</em>
                        <p>{{detail.employer_name}}</p>
                    </li>
                    <li v-if="detail.appraise && detail.appraise.employer_content">
                        <em>雇主评价：</em>
                        <p>{{detail.appraise.employer_content}}</p>
                    </li>
                    <li
                        v-if="detail.appraise && detail.appraise.employer_content"
                        class="row-star starpl-wrap"
                    >
                        <em>工作态度：</em>
                        <el-rate
                            style="float:left;"
                            class="appraise"
                            :value="detail.appraise.attitude_score"
                            disabled
                            show-score
                            text-color="#ff9900"
                            score-template
                        ></el-rate>
                        <span>{{detail.appraise.attitude_score}}分</span>
                    </li>
                    <li
                        v-if="detail.appraise && detail.appraise.employer_content"
                        class="row-star starpl-wrap"
                    >
                        <em>工作速度：</em>
                        <el-rate
                            style="float:left;"
                            class="appraise"
                            :value="detail.appraise.speed_score"
                            disabled
                            show-score
                            text-color="#ff9900"
                            score-template
                        ></el-rate>
                        <span>{{detail.appraise.speed_score}}分</span>
                    </li>
                    <li
                        v-if="detail.appraise && detail.appraise.employer_content"
                        class="row-star starpl-wrap"
                    >
                        <em>工作质量：</em>
                        <el-rate
                            style="float:left;"
                            class="appraise"
                            :value="detail.appraise.quality_score"
                            disabled
                            show-score
                            text-color="#ff9900"
                            score-template
                        ></el-rate>
                        <span>{{detail.appraise.quality_score}}分</span>
                    </li>
                </ul>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="terraceDialog = false">返回</el-button>
                </span>
            </el-dialog>
        </div>

        <div
            class="firm-panel"
            style="border:none;"
            v-if="value.attestation_info && value.attestation_info.achievement.length>0"
        >
            <h3 class="g-title">
                其他业绩
                <span
                    style="padding-left: 20px;font-size: 14px;color: #66666680;"
                >此部份为工程极客自己提交，仅供参考</span>
            </h3>
            <ul class="score-items">
                <li
                    class="item"
                    v-for="(item,index) in value.attestation_info.achievement"
                    :key="index"
                    @click="otherDetailShow(item)"
                    style="height:40px;margin-bottom:10px;border-bottom:1px dashed #ddd;"
                >
                    <el-row>
                        <el-col :span="14" class="title">{{item.title}}</el-col>
                        <el-col :span="10" class="date">时间：{{item.starttime}} - {{item.endtime}}</el-col>
                    </el-row>
                </li>
            </ul>

            <el-dialog title="其他业绩详情" :visible.sync="otherDialog" width="800px" center>
                <ul class="detail-items">
                    <li>
                        <em>项目名称：</em>
                        <h4 v-text="otherDetailData.title"></h4>
                    </li>
                    <li>
                        <em>项目时间：</em>
                        <p v-text="otherDetailData.starttime"></p>
                    </li>
                    <li>
                        <em>项目描述：</em>
                        <p v-text="otherDetailData.description"></p>
                    </li>
                    <li>
                        <em>项目职责：</em>
                        <p v-text="otherDetailData.responsibility"></p>
                    </li>
                    <li>
                        <em>委托单位：</em>
                        <p v-text="otherDetailData.company"></p>
                    </li>
                    <li>
                        <em>项目负责人：</em>
                        <p v-for="(item,index) in otherDetailData.leader" :key="index">{{item}}</p>
                    </li>
                </ul>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="otherDialog = false">返回</el-button>
                </span>
            </el-dialog>
        </div> -->
    </div>
</template>

<script>
export default {
    name: "scoreIndex",
    props: ['value'],
    metaInfo() {
        return {
            title: this.value.name + " - 业绩展示 - 询龙网",
            meta: [
                {
                    name: "keywords",
                    content: "工程极客,工程技术项目,咨询人才,咨询团队,在家工作，众包平台,工程众包,造价工程,项目测绘,项目评估,工程勘测,工程设计,工程造价,工程监理,财务会计,税务服务,工程法务,技术承包,资料外包,造价工程师,装修设计"
                }, {
                    name: "description",
                    content: this.article_content
                }
            ]
        }
    },
    data() {
        return {
            terraceDialog: false,
            otherDialog: false,
            achievementData: '',//平台项目业绩
            detail: '',//平台项目业绩详情
            otherDetailData: '',//其他项目详情
            article_content: '',
            activeName: 'second'
        };
    },
    created() {
        this.loadListData();
    },
    methods: {
        handleClick(tab, event) {
            // console.log(tab, event);
        },
        //加载平台业绩
        loadListData() {
            var _this = this;
            _this.post(
                "/user/index/task_achievement",
                {
                    uk: _this.$route.query.uk
                },
                function (res) {
                    if (res.code == 200) {
                        _this.achievementData = res.data;

                        if (res.data.length > 0) {
                            _this.article_content = res.data[0].task_name.replace(/<[^>].*?>/g, "").replace(/&nbsp;/g, "").substr(0, 300);//截取文章内容，去除html标签
                        } else {
                            if (_this.value.attestation_info.achievement && _this.value.attestation_info.achievement.length > 0) {
                                _this.article_content = _this.value.attestation_info.achievement[0].title.replace(/<[^>].*?>/g, "").replace(/&nbsp;/g, "").substr(0, 300);//截取文章内容，去除html标签
                            }
                        }
                    }
                }
            );
        },
        //平台项目业绩详情
        detailShow(item) {
            this.terraceDialog = true;
            this.detail = '';
            this.detail = item;
        },
        //其他业绩详情
        otherDetailShow(item) {
            this.otherDialog = true;
            this.otherDetailData = '';
            this.otherDetailData = item;
        }
    }
};
</script>

<style lang="less">
.score-items {
    padding: 20px !important;
    .item:hover {
        .title {
            color: #587ddc !important;
        }
        color: #587ddc !important;
    }
}
.appraise span,
.starpl-wrap span {
    width: auto !important;
}

.el-dialog__title {
    font-size: 16px;
}
</style>




